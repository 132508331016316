h1 {
    font-family: Source Sans Pro, sans-serif;
    margin: 0;
}

.adminComponentBody {
    margin-top: 24px;
    margin-bottom: 24px;
}

#admin-component-normal-padding {
    padding: 16px;
}

.admin-component-text-input {
    background: #F6F6F6;
    margin: 16px auto;
}

.adminComponentTopMargin {
    margin-top: 16px;
}

.inputLabel {
    color: black !important;
    font-size: large !important;
}

.textField {
    background: #F6F6F6;
}