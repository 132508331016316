.map {
    height: 300px;
    width: 100%;
}

.mapTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: black;
    text-align: center;
    padding-bottom: 16px;
    padding-top: 32px;
    font-weight: bold;
}

.mapAddress {
    padding-top: 12px;
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    text-align: center;
    padding-bottom: 32px;
    color: black;
    opacity: 0.6;
}