.aboutUs {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 48px 20%;
}

.aboutUsTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: white;
    text-align: center;
    padding-bottom: 16px;
}

.contactBody {
    padding-left: 12px;
    padding-right: 12px;
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    opacity: 0.6;
}

.contactContainer {
    background-color: white;
    padding: 48px 10% 24px;
}

.contactItemContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
}

.contactIcon {
    margin-right: 12px;
}

.contactItem {
    justify-content: center;
    width: 45%;
    padding-bottom: 24px;
    display: flex;
    flex-direction: row;
}

.contactItemInner {
    vertical-align: center;
    padding-bottom: 24px;
    display: flex;
    flex-direction: row;
    align-self: center;
}

.contactPaddingTop4 {
    padding-top: 4px;
}

.contactItem2 {
    justify-content: center;
    width: 45%;
    display: flex;
    flex-direction: column;
}

.contactItem3 {
    justify-content: space-around;
    width: 100%;
    padding-bottom: 24px;
    display: flex;
    flex-direction: row;
}

.contactItem3.contactItemInner {
    width: 47%
}

@media only screen and (max-width: 449px) {
    .contactItemContainer {
        flex-direction: column;
    }

    .contactItem {
        width: 100%;
        justify-content: left;
    }

    .contactItem2 {
        width: 100%;
        justify-content: left;
    }

    .contactItem3 {
        width: 100%;
        justify-content: left;
    }

    .contactItemInner {
        width: 100%;
    }
}

@media only screen and (min-width: 450px) and (max-width: 750px) {
    .contactItemContainer {
        flex-direction: column;
    }

    .contactItem {
        width: 100%;
        justify-content: left;
    }

    .contactItem2 {
        width: 100%;
    }

    .contactItem3 {
        width: 100%;
    }

    .contactItemInner {
        width: 100%;
    }
}

.contactFormTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: black;
    text-align: center;
    padding-bottom: 16px;
    padding-top: 32px;
    font-weight: bold;
    opacity: 0.6;
}

.contactFormText {
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    text-align: center;
    padding-bottom: 32px;
    color: black;
    opacity: 0.6;
}

.contactFormContainer {
    padding: 40px;
    background-color: #F6F6F6;
    margin: 0 10% 15%;
}

.buttonLocator {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactInput {
    background: white;
    padding: 4px !important;
    margin: 10px !important;
}

.linkImage {
    height: 30px;
    width: 30px;
}