.newsBoxPadding {
    padding: 24px;
}

.newsTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: black;
    text-align: center;
    padding-bottom: 16px;
}

.aboutUsBody {
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    color: black;
    opacity: 0.6;
    text-align: center;
    padding-bottom: 16px;
    line-height: 32px;
}

.hotNewsContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 48px 10%;
}

.hotNewsText {
    width: 60%;
    display: flex;
    flex-direction: column;
    padding: 12px;

}

.hotNewsTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    padding-bottom: 12px;
    color: black;
    font-weight: bold;
}

.hotNewsBody {
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    color: black;
    opacity: 0.6;

}

.newsText {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 12px;
}

.hotNews {
    width: 100%;
    height: 200px;
    background: #f0f0f0;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.news {
    width: 30%;
    height: 350px;
    background: #f0f0f0;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.hotNewsImage {
    width: 30%;
    object-fit: cover;
    margin-right: 16px;
}

.newsImage {
    height: 200px;
    object-fit: cover;
}

@media only screen and (max-width: 449px) {
    .hotNews {
        width: 100%;
        height: 350px;
        flex-direction: column;
    }

    .news {
        width: 30%;
        height: 350px;
        flex-direction: column;
    }

    .hotNewsImage {
        width: 100%;
    }

    .newsImage {
        height: 200px;
    }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
    .hotNews {
        width: 100%;
        height: 200px;
    }

    .news {
        width: 40%;
        height: 400px;
    }

    .hotNewsImage {
        width: 40%;
    }

    .newsImage {
        height: 200px;
    }
}