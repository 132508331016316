.productOverview {
    background-color: white;
    padding: 48px 20% 24px;
}

.productOverviewItemContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.productOverviewTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: black;
    text-align: center;
    padding-bottom: 16px;
}

.productOverviewFilterContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.padding {
    padding-left: 12px;
}

.modalPosition {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
}

.modalSize {
    width: 50%;
    background: white;
}

.modalContent {
    padding: 12px 24px 24px 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.table {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.modalHeader {
    font-family: Source Sans Pro, sans-serif;
    color: #58595B;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #F0F0F0;
}

.modalImage {
    width: 300px;
    height: 300px;
}

.productTitle {
    font-family: Source Sans Pro, sans-serif;
    color: #58595B;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 16px;
}

.productPrice {
    padding-top: 16px;
    font-family: Source Sans Pro, sans-serif;
    color: #58595B;
    font-size: 20px;
    font-weight: bold;
}

.productDescription {
    font-family: Source Sans Pro, sans-serif;
    color: #58595B;
    font-size: 16px;
    opacity: 0.6;
}

.toproductButton {
    background-color: #FFD400;
    margin: 8px 5%;
    padding: 8px 0;
    width: 90%;
    font-family: Source Sans Pro, sans-serif;
    color: #58595B;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.modalItem {
    flex-basis: 45%;
}

.imageCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px;
}

@media only screen and (max-width: 449px) {
    .itemImage {
        width: 100%;
        height: 100px;
        padding-bottom: 24px;
        object-fit: cover
    }

    .item {
        cursor: pointer;
        flex-basis: 45%;
        margin-bottom: 24px;
        background-color: #F6F6F6;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }

    .modalItem {
        flex-basis: 75%;
    }

    .modalImage {
        width: 200px;
        height: 200px;
    }

}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
    .itemImage {
        width: 100%;
        height: 125px;
        padding-bottom: 24px;
        object-fit: cover
    }

    .item {
        cursor: pointer;
        flex-basis: 30%;
        margin-bottom: 24px;
        background-color: #F6F6F6;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }

    .modalItem {
        flex-basis: 75%;
    }

    .modalImage {
        width: 200px;
        height: 200px;
    }
}

@media only screen and (min-width: 1200px) {
    .itemImage {
        width: 100%;
        height: 250px;
        padding-bottom: 24px;
        object-fit: cover
    }

    .item {
        cursor: pointer;
        flex-basis: 22%;
        margin-bottom: 24px;
        background-color: #F6F6F6;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }

}