.employee {
    background-color: white;
    padding: 48px 20% 24px;
}

.employeeItemContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.employeeTitle {
     font-family: Source Sans Pro, sans-serif;
     font-size: 42px;
     color: black;
     text-align: center;
     padding-bottom: 16px;

 }

.employeeName {
    padding-top: 12px;
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    color: black;
}

.employeeSkill {
    padding: 4px;
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    text-align: center;
    color: black;
    opacity: 0.6;
}

@media only screen and (max-width: 449px) {
    .employeeItem {
        border-radius: 50%;
        width: 175px;
        height: 175px;
        object-fit: cover;
    }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
    .employeeItem {
        border-radius: 50%;
        width: 175px;
        height: 175px;
        object-fit: cover;
    }
}

@media only screen and (min-width: 1200px) {
    .employeeItem {
        border-radius: 50%;
        width: 250px;
        height: 250px;
        object-fit: cover;
    }
}