.priceList {
    background-color: #F6F6F6;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 48px 20%;
}

.priceListTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: black;
    text-align: center;
    padding-bottom: 16px;
}

.priceListBody {
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    color: black;
    text-align: center;
    padding-bottom: 16px;
    line-height: 32px;
}

.priceListButton {
    font-size: 16px;
    text-decoration: none;
    color: black;
    text-align: center;
    padding: 12px 24px;
    font-family: Source Sans Pro, sans-serif;
    border: 2px solid #FFD400;
    background-color: #FFFFFF;
    cursor: pointer;
    font-weight: bold;
}