.boring {
    padding: 48px 20%;
}

.boringTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 48px;
    color: black;
    padding-bottom: 16px;
}

.boringBody {
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    color: black;
    padding-bottom: 16px;
}