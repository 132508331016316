.newsDetail {
    background-color: #58595B;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 48px 20%;
}

.newsDetailTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: white;
    text-align: center;
    padding-bottom: 16px;
}

.newsDetailBody {
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    color: white;
    text-align: center;
    padding-bottom: 16px;
    line-height: 32px;
}