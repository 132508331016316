.storeOverview {
    background-color: white;
    padding: 48px 20% 24px;
}



.storeOverviewItemContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.storeOverviewTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: black;
    text-align: center;
    padding-bottom: 16px;

}

.storeOverviewFilterContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.table {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.item {
    flex-basis: 30%;
    margin-bottom: 24px;
    background-color: #F6F6F6;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.storeTitle {
    padding: 0 5%;
    font-family: Source Sans Pro, sans-serif;
    color: #58595B;
    font-size: 16px;
    font-weight: bold;
}

.toStoreButton {
    background-color: #FFD400;
    margin: 8px 5%;
    padding: 8px 0;
    width: 90%;
    font-family: Source Sans Pro, sans-serif;
    color: #58595B;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

@media only screen and (max-width: 449px) {
    .itemImage {
        width: 100%;
        height: 175px;
        padding-bottom: 24px;
        object-fit: cover
    }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
    .itemImage {
        width: 100%;
        height: 175px;
        padding-bottom: 24px;
        object-fit: cover
    }
}

@media only screen and (min-width: 1200px) {
    .itemImage {
        width: 100%;
        height: 250px;
        padding-bottom: 24px;
        object-fit: cover
    }
}