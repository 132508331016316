.headeOverlay {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 24%;
    padding-right: 24%;
}

.headerContent {
    color: #FFFFFF;
    font-family: Source Sans Pro, sans-serif;
    font-size: 56px;
    font-weight: bold;
    text-align: center;
}

@media only screen and (max-width: 449px) {
    .header {
        height: 340px;
        background: no-repeat center;
        background-size: cover;
    }

    .headerBlendMode {
        height: 340px;
        background: #FFD400 no-repeat center;
        background-size: cover;
        background-blend-mode: multiply;
    }
}

@media only screen and (min-width: 450px) and (max-width: 1399px) {
    .header {
        height: 440px;
        background: no-repeat center;
        background-size: cover;
    }

    .headerBlendMode {
        height: 440px;
        background: #FFD400 no-repeat center;
        background-size: cover;
        background-blend-mode: multiply;
    }
}

@media only screen and (min-width: 1400px) {
    .header {
        height: 740px;
        background: no-repeat center;
        background-size: cover;
    }

    .headerBlendMode {
        height: 740px;
        background: #FFD400 no-repeat center;
        background-size: cover;
        background-blend-mode: multiply;
    }
}
