
.navigationButton {
    height: 80px;
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    color: black;
    text-decoration: none;
    margin-right: 24px;
    padding-bottom: 2px;
    margin-bottom: 12px;
    white-space: nowrap;
    line-height: 19px;
    cursor: pointer;
    text-transform: uppercase;
    border-bottom: 4px solid transparent;
    width: 100%;
}

.navigationButton:hover {
    border-bottom: 4px solid #FFD400;
}

.navigationButton.active {
    border-bottom: 4px solid #FFD400;
}

.navigation {
    background-color: white;
    padding-right: 10%;
    padding-left: 10%;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
}

.navigation div:last-child {
    margin-left: auto;
}

.navigationLogo {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 60px;
}

#navigationBar {
    padding-left: 0;
    padding-right: 0;
}