.modalCookiePosition {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
}

.modalCookieBackground {
    margin-left: 15%;
    margin-right: 15%;
    padding: 40px;
    background: #f0f0f0;
    font-family: Source Sans Pro, sans-serif;
    font-size: 22px;
    text-align: center;
}

@media only screen and (max-width: 449px) {
    .modalCookieBackground {
        margin-left: 5%;
        margin-right: 5%;
    }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
    .modalCookieBackground {
        margin-left: 15%;
        margin-right: 15%;
    }
}

@media only screen and (min-width: 1200px) {
    .modalCookieBackground {
        margin-left: 25%;
        margin-right: 25%;
    }

}