.footer {
    background-color: #F6F6F6;
    display: flex;
    align-items: center;
    padding: 48px 20%;
}

.footerTable {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}



.footerLogo {
    margin-right: 95px;
    height: 150px;
}

.title {
    color: #58595B;
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    font-weight: bold;
}

.text {
    opacity: 0.6;
    color: #000000;
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
}

.footerLink {
    color: #FFD400;
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    font-weight: bold;
    flex-basis: 30%;
    padding-bottom: 12px;
    cursor: pointer;
}

.footerInfo {
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    flex-basis: 30%;
    padding-bottom: 12px;
}

.footerLinkBar {
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 20%;
}

pre {
    font-weight: bold;
    margin: 0;
}

.footerItem {
    flex-basis: 45%;
    padding-bottom: 12px;
}

@media only screen and (min-width: 1400px) {
    .footerItem {
        flex-basis: 30%;
        padding-bottom: 12px;
    }
}
