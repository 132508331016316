.gallery {
    background-color: white;
    padding: 48px 20% 24px;
}

.galleryItemContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.galleryTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: black;
    text-align: center;
    padding-bottom: 16px;

}

.galleryFilterContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.galleryFilter {
    color: #58595B;
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    border: 2px solid #58595B;
    border-radius: 16px;
    margin-right: 10px;
    margin-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
}

.galleryFilter.active {
    color: white;
    background: #58595B;
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    border: 2px solid #58595B;
    border-radius: 16px;
    margin-right: 10px;
    margin-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
}


.modalPositionGallery {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 449px) {
    .galleryItem {
        width: 80%;
        cursor: pointer;
        height: 175px;
        padding-bottom: 24px;
        object-fit: cover
    }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
    .galleryItem {
        width: 48%;
        cursor: pointer;
        height: 175px;
        padding-bottom: 24px;
        object-fit: cover
    }
}

@media only screen and (min-width: 1200px) {
    .galleryItem {
        cursor: pointer;
        width: 32%;
        height: 250px;
        padding-bottom: 24px;
        object-fit: cover
    }
}