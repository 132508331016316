body {
    margin: 0;
    padding: 0;
}

.modalCookiePosition {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
}

.modalCookieBackground {
    margin-left: 15%;
    margin-right: 15%;
    padding: 40px;
    background: #f0f0f0;
    font-family: Source Sans Pro, sans-serif;
    font-size: 22px;
    text-align: center;
}

@media only screen and (max-width: 449px) {
    .modalCookieBackground {
        margin-left: 5%;
        margin-right: 5%;
    }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
    .modalCookieBackground {
        margin-left: 15%;
        margin-right: 15%;
    }
}

@media only screen and (min-width: 1200px) {
    .modalCookieBackground {
        margin-left: 25%;
        margin-right: 25%;
    }

}
.headeOverlay {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 24%;
    padding-right: 24%;
}

.headerContent {
    color: #FFFFFF;
    font-family: Source Sans Pro, sans-serif;
    font-size: 56px;
    font-weight: bold;
    text-align: center;
}

@media only screen and (max-width: 449px) {
    .header {
        height: 340px;
        background: no-repeat center;
        background-size: cover;
    }

    .headerBlendMode {
        height: 340px;
        background: #FFD400 no-repeat center;
        background-size: cover;
        background-blend-mode: multiply;
    }
}

@media only screen and (min-width: 450px) and (max-width: 1399px) {
    .header {
        height: 440px;
        background: no-repeat center;
        background-size: cover;
    }

    .headerBlendMode {
        height: 440px;
        background: #FFD400 no-repeat center;
        background-size: cover;
        background-blend-mode: multiply;
    }
}

@media only screen and (min-width: 1400px) {
    .header {
        height: 740px;
        background: no-repeat center;
        background-size: cover;
    }

    .headerBlendMode {
        height: 740px;
        background: #FFD400 no-repeat center;
        background-size: cover;
        background-blend-mode: multiply;
    }
}

.aboutUs {
    background-color: #58595B;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 48px 20%;
}

.aboutUsTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: white;
    text-align: center;
    padding-bottom: 16px;
}

.aboutUsBodyText {
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    color: white;
    text-align: center;
    padding-bottom: 16px;
    line-height: 32px;
}
.gallery {
    background-color: white;
    padding: 48px 20% 24px;
}

.galleryItemContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.galleryTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: black;
    text-align: center;
    padding-bottom: 16px;

}

.galleryFilterContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.galleryFilter {
    color: #58595B;
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    border: 2px solid #58595B;
    border-radius: 16px;
    margin-right: 10px;
    margin-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
}

.galleryFilter.active {
    color: white;
    background: #58595B;
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    line-height: 32px;
    text-align: center;
    border: 2px solid #58595B;
    border-radius: 16px;
    margin-right: 10px;
    margin-bottom: 16px;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
}


.modalPositionGallery {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media only screen and (max-width: 449px) {
    .galleryItem {
        width: 80%;
        cursor: pointer;
        height: 175px;
        padding-bottom: 24px;
        object-fit: cover
    }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
    .galleryItem {
        width: 48%;
        cursor: pointer;
        height: 175px;
        padding-bottom: 24px;
        object-fit: cover
    }
}

@media only screen and (min-width: 1200px) {
    .galleryItem {
        cursor: pointer;
        width: 32%;
        height: 250px;
        padding-bottom: 24px;
        object-fit: cover
    }
}
.footer {
    background-color: #F6F6F6;
    display: flex;
    align-items: center;
    padding: 48px 20%;
}

.footerTable {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}



.footerLogo {
    margin-right: 95px;
    height: 150px;
}

.title {
    color: #58595B;
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    font-weight: bold;
}

.text {
    opacity: 0.6;
    color: #000000;
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
}

.footerLink {
    color: #FFD400;
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    font-weight: bold;
    flex-basis: 30%;
    padding-bottom: 12px;
    cursor: pointer;
}

.footerInfo {
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    flex-basis: 30%;
    padding-bottom: 12px;
}

.footerLinkBar {
    background-color: white;
    display: flex;
    align-items: center;
    padding: 0 20%;
}

pre {
    font-weight: bold;
    margin: 0;
}

.footerItem {
    flex-basis: 45%;
    padding-bottom: 12px;
}

@media only screen and (min-width: 1400px) {
    .footerItem {
        flex-basis: 30%;
        padding-bottom: 12px;
    }
}

.priceList {
    background-color: #F6F6F6;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 48px 20%;
}

.priceListTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: black;
    text-align: center;
    padding-bottom: 16px;
}

.priceListBody {
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    color: black;
    text-align: center;
    padding-bottom: 16px;
    line-height: 32px;
}

.priceListButton {
    font-size: 16px;
    text-decoration: none;
    color: black;
    text-align: center;
    padding: 12px 24px;
    font-family: Source Sans Pro, sans-serif;
    border: 2px solid #FFD400;
    background-color: #FFFFFF;
    cursor: pointer;
    font-weight: bold;
}
.storeOverview {
    background-color: white;
    padding: 48px 20% 24px;
}

.storeOverviewItemContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.storeOverviewTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: black;
    text-align: center;
    padding-bottom: 16px;

}

.storeOverviewFilterContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.table {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.item {
    flex-basis: 30%;
    margin-bottom: 24px;
    background-color: #F6F6F6;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.storeTitle {
    padding: 0 5%;
    font-family: Source Sans Pro, sans-serif;
    color: #58595B;
    font-size: 16px;
    font-weight: bold;
}

.toStoreButton {
    background-color: #FFD400;
    margin: 8px 5%;
    padding: 8px 0;
    width: 90%;
    font-family: Source Sans Pro, sans-serif;
    color: #58595B;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

@media only screen and (max-width: 449px) {
    .itemImage {
        width: 100%;
        height: 175px;
        padding-bottom: 24px;
        object-fit: cover
    }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
    .itemImage {
        width: 100%;
        height: 175px;
        padding-bottom: 24px;
        object-fit: cover
    }
}

@media only screen and (min-width: 1200px) {
    .itemImage {
        width: 100%;
        height: 250px;
        padding-bottom: 24px;
        object-fit: cover
    }
}
h1 {
    font-family: Source Sans Pro, sans-serif;
    margin: 0;
}

.adminComponentBody {
    margin-top: 24px;
    margin-bottom: 24px;
}

#admin-component-normal-padding {
    padding: 16px;
}

.admin-component-text-input {
    background: #F6F6F6;
    margin: 16px auto;
}

.adminComponentTopMargin {
    margin-top: 16px;
}

.inputLabel {
    color: black !important;
    font-size: large !important;
}

.textField {
    background: #F6F6F6;
}

.navigationButton {
    height: 80px;
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    color: black;
    text-decoration: none;
    margin-right: 24px;
    padding-bottom: 2px;
    margin-bottom: 12px;
    white-space: nowrap;
    line-height: 19px;
    cursor: pointer;
    text-transform: uppercase;
    border-bottom: 4px solid transparent;
    width: 100%;
}

.navigationButton:hover {
    border-bottom: 4px solid #FFD400;
}

.navigationButton.active {
    border-bottom: 4px solid #FFD400;
}

.navigation {
    background-color: white;
    padding-right: 10%;
    padding-left: 10%;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-wrap: wrap;
}

.navigation div:last-child {
    margin-left: auto;
}

.navigationLogo {
    padding-top: 10px;
    padding-bottom: 10px;
    height: 60px;
}

#navigationBar {
    padding-left: 0;
    padding-right: 0;
}
.productOverview {
    background-color: white;
    padding: 48px 20% 24px;
}

.productOverviewItemContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.productOverviewTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: black;
    text-align: center;
    padding-bottom: 16px;
}

.productOverviewFilterContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.padding {
    padding-left: 12px;
}

.modalPosition {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: auto;
}

.modalSize {
    width: 50%;
    background: white;
}

.modalContent {
    padding: 12px 24px 24px 24px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.table {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.modalHeader {
    font-family: Source Sans Pro, sans-serif;
    color: #58595B;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    padding-top: 12px;
    padding-bottom: 12px;
    background-color: #F0F0F0;
}

.modalImage {
    width: 300px;
    height: 300px;
}

.productTitle {
    font-family: Source Sans Pro, sans-serif;
    color: #58595B;
    font-size: 20px;
    font-weight: bold;
    padding-bottom: 16px;
}

.productPrice {
    padding-top: 16px;
    font-family: Source Sans Pro, sans-serif;
    color: #58595B;
    font-size: 20px;
    font-weight: bold;
}

.productDescription {
    font-family: Source Sans Pro, sans-serif;
    color: #58595B;
    font-size: 16px;
    opacity: 0.6;
}

.toproductButton {
    background-color: #FFD400;
    margin: 8px 5%;
    padding: 8px 0;
    width: 90%;
    font-family: Source Sans Pro, sans-serif;
    color: #58595B;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

.modalItem {
    flex-basis: 45%;
}

.imageCenter {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px;
}

@media only screen and (max-width: 449px) {
    .itemImage {
        width: 100%;
        height: 100px;
        padding-bottom: 24px;
        object-fit: cover
    }

    .item {
        cursor: pointer;
        flex-basis: 45%;
        margin-bottom: 24px;
        background-color: #F6F6F6;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }

    .modalItem {
        flex-basis: 75%;
    }

    .modalImage {
        width: 200px;
        height: 200px;
    }

}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
    .itemImage {
        width: 100%;
        height: 125px;
        padding-bottom: 24px;
        object-fit: cover
    }

    .item {
        cursor: pointer;
        flex-basis: 30%;
        margin-bottom: 24px;
        background-color: #F6F6F6;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }

    .modalItem {
        flex-basis: 75%;
    }

    .modalImage {
        width: 200px;
        height: 200px;
    }
}

@media only screen and (min-width: 1200px) {
    .itemImage {
        width: 100%;
        height: 250px;
        padding-bottom: 24px;
        object-fit: cover
    }

    .item {
        cursor: pointer;
        flex-basis: 22%;
        margin-bottom: 24px;
        background-color: #F6F6F6;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
    }

}
.storeOverview {
    background-color: white;
    padding: 48px 20% 24px;
}



.storeOverviewItemContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.storeOverviewTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: black;
    text-align: center;
    padding-bottom: 16px;

}

.storeOverviewFilterContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.table {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
}

.item {
    flex-basis: 30%;
    margin-bottom: 24px;
    background-color: #F6F6F6;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
}

.storeTitle {
    padding: 0 5%;
    font-family: Source Sans Pro, sans-serif;
    color: #58595B;
    font-size: 16px;
    font-weight: bold;
}

.toStoreButton {
    background-color: #FFD400;
    margin: 8px 5%;
    padding: 8px 0;
    width: 90%;
    font-family: Source Sans Pro, sans-serif;
    color: #58595B;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
}

@media only screen and (max-width: 449px) {
    .itemImage {
        width: 100%;
        height: 175px;
        padding-bottom: 24px;
        object-fit: cover
    }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
    .itemImage {
        width: 100%;
        height: 175px;
        padding-bottom: 24px;
        object-fit: cover
    }
}

@media only screen and (min-width: 1200px) {
    .itemImage {
        width: 100%;
        height: 250px;
        padding-bottom: 24px;
        object-fit: cover
    }
}
.openingHours {
    background-color: #1d1d1b;
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 48px 20%;
}

.openingHoursTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: white;
    text-align: center;
    padding-bottom: 16px;
}

.openingHoursBody {
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    color: #FFD400;
    text-align: center;
    padding-bottom: 16px;
    line-height: 32px;
}
.map {
    height: 300px;
    width: 100%;
}

.mapTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: black;
    text-align: center;
    padding-bottom: 16px;
    padding-top: 32px;
    font-weight: bold;
}

.mapAddress {
    padding-top: 12px;
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    text-align: center;
    padding-bottom: 32px;
    color: black;
    opacity: 0.6;
}
.employee {
    background-color: white;
    padding: 48px 20% 24px;
}

.employeeItemContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.employeeTitle {
     font-family: Source Sans Pro, sans-serif;
     font-size: 42px;
     color: black;
     text-align: center;
     padding-bottom: 16px;

 }

.employeeName {
    padding-top: 12px;
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    text-align: center;
    font-weight: bold;
    color: black;
}

.employeeSkill {
    padding: 4px;
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    text-align: center;
    color: black;
    opacity: 0.6;
}

@media only screen and (max-width: 449px) {
    .employeeItem {
        border-radius: 50%;
        width: 175px;
        height: 175px;
        object-fit: cover;
    }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
    .employeeItem {
        border-radius: 50%;
        width: 175px;
        height: 175px;
        object-fit: cover;
    }
}

@media only screen and (min-width: 1200px) {
    .employeeItem {
        border-radius: 50%;
        width: 250px;
        height: 250px;
        object-fit: cover;
    }
}
.boring {
    padding: 48px 20%;
}

.boringTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 48px;
    color: black;
    padding-bottom: 16px;
}

.boringBody {
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    color: black;
    padding-bottom: 16px;
}
.aboutUs {
    align-items: center;
    justify-content: center;
    display: flex;
    padding: 48px 20%;
}

.aboutUsTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: white;
    text-align: center;
    padding-bottom: 16px;
}

.contactBody {
    padding-left: 12px;
    padding-right: 12px;
    font-family: Source Sans Pro, sans-serif;
    font-size: 16px;
    opacity: 0.6;
}

.contactContainer {
    background-color: white;
    padding: 48px 10% 24px;
}

.contactItemContainer {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
}

.contactIcon {
    margin-right: 12px;
}

.contactItem {
    justify-content: center;
    width: 45%;
    padding-bottom: 24px;
    display: flex;
    flex-direction: row;
}

.contactItemInner {
    vertical-align: center;
    padding-bottom: 24px;
    display: flex;
    flex-direction: row;
    align-self: center;
}

.contactPaddingTop4 {
    padding-top: 4px;
}

.contactItem2 {
    justify-content: center;
    width: 45%;
    display: flex;
    flex-direction: column;
}

.contactItem3 {
    justify-content: space-around;
    width: 100%;
    padding-bottom: 24px;
    display: flex;
    flex-direction: row;
}

.contactItem3.contactItemInner {
    width: 47%
}

@media only screen and (max-width: 449px) {
    .contactItemContainer {
        flex-direction: column;
    }

    .contactItem {
        width: 100%;
        justify-content: left;
    }

    .contactItem2 {
        width: 100%;
        justify-content: left;
    }

    .contactItem3 {
        width: 100%;
        justify-content: left;
    }

    .contactItemInner {
        width: 100%;
    }
}

@media only screen and (min-width: 450px) and (max-width: 750px) {
    .contactItemContainer {
        flex-direction: column;
    }

    .contactItem {
        width: 100%;
        justify-content: left;
    }

    .contactItem2 {
        width: 100%;
    }

    .contactItem3 {
        width: 100%;
    }

    .contactItemInner {
        width: 100%;
    }
}

.contactFormTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: black;
    text-align: center;
    padding-bottom: 16px;
    padding-top: 32px;
    font-weight: bold;
    opacity: 0.6;
}

.contactFormText {
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    text-align: center;
    padding-bottom: 32px;
    color: black;
    opacity: 0.6;
}

.contactFormContainer {
    padding: 40px;
    background-color: #F6F6F6;
    margin: 0 10% 15%;
}

.buttonLocator {
    margin-top: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contactInput {
    background: white;
    padding: 4px !important;
    margin: 10px !important;
}

.linkImage {
    height: 30px;
    width: 30px;
}
.newsBoxPadding {
    padding: 24px;
}

.newsTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: black;
    text-align: center;
    padding-bottom: 16px;
}

.aboutUsBody {
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    color: black;
    opacity: 0.6;
    text-align: center;
    padding-bottom: 16px;
    line-height: 32px;
}

.hotNewsContainer {
    display: flex;
    flex-wrap: wrap;
    padding: 48px 10%;
}

.hotNewsText {
    width: 60%;
    display: flex;
    flex-direction: column;
    padding: 12px;

}

.hotNewsTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    padding-bottom: 12px;
    color: black;
    font-weight: bold;
}

.hotNewsBody {
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: Source Sans Pro, sans-serif;
    font-size: 14px;
    color: black;
    opacity: 0.6;

}

.newsText {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 12px;
}

.hotNews {
    width: 100%;
    height: 200px;
    background: #f0f0f0;
    display: flex;
    flex-direction: row;
    cursor: pointer;
}

.news {
    width: 30%;
    height: 350px;
    background: #f0f0f0;
    display: flex;
    flex-direction: column;
    cursor: pointer;
}

.hotNewsImage {
    width: 30%;
    object-fit: cover;
    margin-right: 16px;
}

.newsImage {
    height: 200px;
    object-fit: cover;
}

@media only screen and (max-width: 449px) {
    .hotNews {
        width: 100%;
        height: 350px;
        flex-direction: column;
    }

    .news {
        width: 30%;
        height: 350px;
        flex-direction: column;
    }

    .hotNewsImage {
        width: 100%;
    }

    .newsImage {
        height: 200px;
    }
}

@media only screen and (min-width: 450px) and (max-width: 1199px) {
    .hotNews {
        width: 100%;
        height: 200px;
    }

    .news {
        width: 40%;
        height: 400px;
    }

    .hotNewsImage {
        width: 40%;
    }

    .newsImage {
        height: 200px;
    }
}
.newsDetail {
    background-color: #58595B;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 48px 20%;
}

.newsDetailTitle {
    font-family: Source Sans Pro, sans-serif;
    font-size: 42px;
    color: white;
    text-align: center;
    padding-bottom: 16px;
}

.newsDetailBody {
    font-family: Source Sans Pro, sans-serif;
    font-size: 18px;
    color: white;
    text-align: center;
    padding-bottom: 16px;
    line-height: 32px;
}
.appOverlay {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 24%;
    padding-right: 24%;
}

.appContent {
    color: #FFFFFF;
    font-family: Source Sans Pro, sans-serif;
    font-size: 56px;
    font-weight: bold;
    text-align: center;
}


