.appOverlay {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 24%;
    padding-right: 24%;
}

.appContent {
    color: #FFFFFF;
    font-family: Source Sans Pro, sans-serif;
    font-size: 56px;
    font-weight: bold;
    text-align: center;
}

